import { configureStore } from '@reduxjs/toolkit';

import activeAccordionItemReducer from './reducers/accordionItemOpen';
import activityCodesReducer from './reducers/ActivityCodes';
import authenticationReducer from './reducers/authentication';
import cashFlowReducer from './reducers/cashflow';
import changesReducer from './reducers/changes';
import deviationsReducer from './reducers/deviations';
import documentReducer from './reducers/document';
import economyMonitoringReducer from './reducers/economyMonitoring';
import economyReportReducer from './reducers/economyReport';
import emergenciesReducer from './reducers/emergencies';
import fileReducer from './reducers/file';
import fileSelectionReducer from './reducers/files';
import inviteReducer from './reducers/invite';
import loadingReducer from './reducers/loading';
import navigationReducer from './reducers/navigation';
import notificationReducer from './reducers/notification';
import onboardingReducer from './reducers/onboarding';
import currentStepReducer from './reducers/onboardingFormStep';
import paymentScheduleReducer from './reducers/payment-schedule';
import precalculationsReducer from './reducers/precalculations';
import productionBudgetReducer from './reducers/production-budget';
import projectReducer from './reducers/project';
import reportsReducer from './reducers/reports';
import subpanelLockReducer from './reducers/subpanelLock';
import teamReducer from './reducers/team';
import utilitiesReducer from './reducers/utilities/utilities.reducer';
import workingEnvironmentReducer from './reducers/workingEnvironment';
import workingEnvironmentEntryReducer from './reducers/workingEnvironmentEntryReducer';
import worksiteReducer from './reducers/worksites';

const store = configureStore({
  reducer: {
    authentication: authenticationReducer,
    activityCodes: activityCodesReducer,
    navigation: navigationReducer,
    team: teamReducer,
    loading: loadingReducer,
    invite: inviteReducer,
    file: fileReducer,
    fileSelection: fileSelectionReducer,
    project: projectReducer,
    reports: reportsReducer,
    precalculations: precalculationsReducer,
    productionBudget: productionBudgetReducer,
    lockSubpanels: subpanelLockReducer,
    cashFlow: cashFlowReducer,
    paymentSchedule: paymentScheduleReducer,
    economyReport: economyReportReducer,
    desktopView: utilitiesReducer,
    onboarding: onboardingReducer,
    formStep: currentStepReducer,
    notification: notificationReducer,
    workingEnvironment: workingEnvironmentReducer,
    economyMonitoring: economyMonitoringReducer,
    deviations: deviationsReducer,
    changes: changesReducer,
    workingEnvironmentEntry: workingEnvironmentEntryReducer,
    activeAccordionItem: activeAccordionItemReducer,
    document: documentReducer,
    worksites: worksiteReducer,
    emergencies: emergenciesReducer,
  }
});

export type AppDispatch = typeof store.dispatch;
export type RootState = ReturnType<typeof store.getState>;
export default store;
