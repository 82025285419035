import { IonCol, IonGrid, IonRow } from '@ionic/react';

import BigUp from '../..';
import type { SecondaryProps } from '../../Buttons/interface';

export interface TableHeaderProps {
  title: string;
  button: SecondaryProps;
}

const TableHeader: React.FC<TableHeaderProps> = (props) => {
  return (
    <IonGrid>
      <IonRow className='ion-justify-content-between ion-align-items-center'>
        <IonCol sizeXs='8' size='auto'>
          <BigUp.Title label={props.title} />
        </IonCol>
        <IonCol size='auto' className='ion-text-right  ion-margin-right ion-text-right'>
          <BigUp.Buttons.Secondary {...props.button} />
        </IonCol>
      </IonRow>
    </IonGrid>
  );
};

export default TableHeader;
