import { IonButton, IonCol, IonGrid, IonIcon, IonRow, IonToolbar } from '@ionic/react';
import React from 'react';
import { useTranslation } from 'react-i18next';

import type { ToolbarButtonProps } from './Tabs';
import styles from './TabToolbar.module.scss';
import { useAppSelector } from '../../../../hooks';
import { setSelectedFiles } from '../../../../reducers/files';
import store from '../../../../store';

const TabToolbar: React.FC<ToolbarButtonProps> = (props) => {
  const { t } = useTranslation();
  const files = useAppSelector(state => state.fileSelection.files);
  const selection = useAppSelector(state => state.fileSelection.selectedFiles);

  return (
    <IonToolbar className={styles['tab-toolbar']} color={'none'}>
      <div className={styles['tab-toolbar__buttons-container']} style={{ width: '100%', background: 'transparent' }}>
        {(selection && selection.length > 0)
          ? <>
            <IonGrid className='ion-no-padding'>
              <IonRow className='ion-justify-content-evenly ion-align-items-center'>
                <IonCol className='ion-no-padding' size='auto'>
                  <IonButton fill={'clear'} color={'medium'} onClick={() => store.dispatch(setSelectedFiles([]))}>{t('Deselect all')}</IonButton>
                </IonCol>
                <IonCol className='ion-no-padding' size='auto'>
                  <p>{t('{selectionLength} files selected', { selectionLength: selection.length })}</p>
                </IonCol>
                <IonCol className='ion-no-padding' size='auto'>
                  <IonButton fill='clear' color={'secondary'} onClick={() => store.dispatch(setSelectedFiles(files))}>{t('Select all')}</IonButton>
                </IonCol>
              </IonRow>
            </IonGrid>
          </>
          : (
            <>
              {props.buttons.map((button, index) => (
                <button
                  key={index}
                  className={
                    `${styles['tab-toolbar__button']} ${button.isActive
                      ? styles['tab-toolbar__button--active']
                      : ''
                    }`
                  }
                  style={
                    button.isActive && button.activeColor
                      ? { color: button.activeColor.textColour || 'var(--ion-color-dark)', }
                      : {}
                  }
                  onClick={button.isActive ? undefined : button.onClick}
                  disabled={!!button.isActive}
                >
                  <IonIcon {...button.icon} size='large' className={styles['tab-toolbar__icon']} />
                  <div className={styles['tab-toolbar__label']}>{button.label}</div>
                </button>
              ))}
            </>
          )
        }

      </div>
    </IonToolbar>
  );
};

export default TabToolbar;
