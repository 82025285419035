import { IonButton, IonIcon } from '@ionic/react';

import BigUp from '..';
import { useAppSelector } from '../../../hooks';

interface ListToggleProps {
  handleClick: () => void;
  disable?: boolean;
}

const ListToggle: React.FC<ListToggleProps> = ({ disable = false, handleClick, }) => {
  const toggle = useAppSelector(state => state.fileSelection.toggleListView);
  return (
    <IonButton
      fill='clear'
      onClick={(e) => {
        handleClick();
        e.preventDefault();
        e.stopPropagation();
      }}
      disabled={disable}
    >
      <IonIcon
        slot='icon-only'
        size='large'
        icon={
          toggle
            ? BigUp.Icons.Svg.GridIcon
            : BigUp.Icons.Svg.ListIcon
        }
      />
    </IonButton>
  );
};

export default ListToggle;
