import { IonButton, IonCheckbox, IonIcon, IonItem, IonLabel } from '@ionic/react';
import type { ComponentProps } from 'react';
import { useState } from 'react';
import type { RegisterOptions } from 'react-hook-form';

import type { HTMLGenericProps } from '../generic.interface';
import styles from './CheckboxPreview.module.scss';
import { checkFileType } from './utils';

type CheckboxComponentProps = ComponentProps<typeof IonCheckbox>
  & HTMLGenericProps<HTMLIonCheckboxElement>;

export interface CheckboxProps extends CheckboxComponentProps {
  children?: React.ReactNode;
  touch?: {
    touchEnd: () => void;
    touchStart: () => void;
  }
  note?: string;
  subNote?: string;
  itemProps?: ComponentProps<typeof IonItem>;
  validation?: RegisterOptions;
  handleCheckbox: (value: boolean) => void;
  image: {
    src: HTMLImageElement['src'];
    alt: HTMLImageElement['alt'];
    onError?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
    onErrorCapture?: (event: React.SyntheticEvent<HTMLImageElement, Event>) => void;
  },
  title?: string;
  subtitle?: string;
  loading?: boolean;
  fileType: string;
  type: string | undefined;
}

export const CheckboxPreview: React.FC<CheckboxProps> = ({
  children,
  handleCheckbox,
  image = {
    src: 'TODO: ADD FALLBACK IMAGE',
    alt: 'Fallback Image Description',
  },
  itemProps,
  loading,
  note,
  subNote,
  subtitle,
  title,
  validation,
  ...props
}) => {
  const [_, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  return (
    <div className={styles['image-card__container']}>
      <IonButton
        size='small'
        fill="clear"
        className={styles['image-card']}
        onTouchEnd={props.touch?.touchEnd}
        onTouchStart={props.touch?.touchStart}
        onClick={() => {
          handleCheckbox(!props.checked);
        }}
      >
        {props.fileType.includes('image') && (

          <img
            onLoadCapture={handleImageLoad}
            className={styles['image-card__image']}
            src={image?.src || 'TODO: ADD FALLBACK IMAGE'}
            onError={(event) => image?.onError?.(event)}
            onErrorCapture={(event) => image?.onErrorCapture?.(event)}
          />
        )}
        {!props.fileType.includes('image') && (
          <IonIcon
            className={styles['image-card__icon']}
            icon={checkFileType(props.fileType)}
            style={{
              fontSize: '3rem',
            }}
          />
        )}
        <IonCheckbox
          {...props}
          className={styles['image-card__checkbox']}
          mode="ios"
          value={props.value}
          checked={props.checked}
          onIonChange={(e) => {
            handleCheckbox(e.detail.checked);
          }}
          disabled={props.disabled ?? false}
        />
      </IonButton>
      <IonItem
        lines="none"
        style={{
          textAlign: 'center'
        }}
        className={`
          ${styles['image-card__item']} 
          ${props.checked
      ? styles['image-card__item--checked']
      : styles['image-card__item--un-checked']}
        `}
      >
        <IonLabel className={`${styles['image-card__label']} ion-no-margin`}>
          <h3 className={styles['image-card__label-heading']}>{title || 'FILENAME'}</h3>
          <p className={styles['image-card__label-subtext']}>{subtitle || 'YYYY-MM-DD'}</p>
        </IonLabel>
      </IonItem>
    </div>
  );
};
