import { IonContent } from '@ionic/react';

import { marginTop } from './BigUp/constants';

const ContentWrapper = ({ children }: { children: React.ReactNode }) => {
  return (
    <IonContent
      fullscreen
      className="ion-padding"
      style={{
        marginTop,
        '--background': 'var(--color-white)',
      }}
    >
      {children}
    </IonContent>
  );
};

export default ContentWrapper;
