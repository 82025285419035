import {
  IonCol,
  IonGrid,
  IonIcon,
  IonRow,
  useIonRouter,
  useIonViewDidEnter,
  useIonViewWillEnter,
  useIonViewWillLeave
} from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { cog } from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import LatestFileList from './LatestFileList';
import { networking } from '../../../api/networking';
import SkeletonTextThreeLines from '../../../components/SkeletonComponents/SkeletonTextThreeLines';
import toasters from '../../../components/Toasts/Toasts';
import BigUp from '../../../components/UI';
import { MultiSelectorGroup } from '../../../components/UI/MultiSelector';
import type { MultiSelectorGroupValuesProps } from '../../../components/UI/MultiSelector/interfaces';
import SideMenuV2Layout from '../../../components/UI/SideMenu/V2/SideMenuV2Layout';
import { useAppSelector } from '../../../hooks';
import type { SourceUrlProps } from '../../../interfaces/SourceUrlProps';
import { setSelectedCategory } from '../../../reducers/document';
import { setShouldRefetchDocuments } from '../../../reducers/file';
import { setForceReloadDocument } from '../../../reducers/files';
import { setIsLoading } from '../../../reducers/loading';
import store from '../../../store';
import CategoryTitleColumn from '../../Document/Category/CategoryTitleColumn';
import FilePreviewColumn from '../../Document/Columns/FilePreviewColumn';
import { getDocumentRoute } from '../../Document/DocumentsListTable/documentSwitch';
import { DocumentActivityCodeFilter, DocumentExtensionCodeFilter } from '../../Document/Filters';

const DocumentCategoryPage: React.FC = () => {
  const isLoadingType = useAppSelector(state => state.loading.isLoading.documentType);
  const { category_id } = useParams<{ uuid: string, category_id: string }>();
  const { t } = useTranslation();
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const router = useIonRouter();
  const lastReloadTs = useAppSelector(state => state.file.lastReloadTs);
  const parsedCategoryId = useMemo(() => category_id === 'uncategorized' ? 'none' : category_id, [category_id]);
  const selectedProjectId = useMemo(() => selectedProject?.id, [selectedProject]);
  const category = useAppSelector(state => state.document.selectedCategory);
  const forceReloadDocument = useAppSelector(state => state.fileSelection.forceReloadDocument);

  const [searchQuery, setSearchQuery] = useState<string>('');
  const [activeFilters, setActiveFilters] = useState<MultiSelectorGroupValuesProps>({});
  const [previousDataSourceUrl, setPreviousDataSourceUrl] = useState<SourceUrlProps | undefined>(undefined);

  const defaultUrl: SourceUrlProps = useMemo(() => {
    return {
      url: `/api/v1/projects/${selectedProjectId}/documents`,
      args: {
        per_page: 9999,
        'with[]': 'files',
        filters: JSON.stringify([{
          field: 'document_type',
          value: [parsedCategoryId]
        }]),
      }
    };
  }, [parsedCategoryId, selectedProjectId]);

  const dataSourceUrl = useMemo(() => {
    if (!selectedProjectId) {
      return undefined;
    }

    let args: any = { search: searchQuery };

    if (
      previousDataSourceUrl !== undefined &&
      typeof previousDataSourceUrl === 'object' &&
      typeof previousDataSourceUrl.args !== 'undefined'
    ) {
      args = { ...previousDataSourceUrl.args, ...args };
    }

    if (activeFilters) {
      args = { ...activeFilters, 'filters[categories]': [parsedCategoryId] };
    }

    return (searchQuery.length > 0 || Object.keys(activeFilters).length)
      ? {
        args,
        url: `/api/v1/projects/${selectedProjectId}/documents/files`,
      }
      : defaultUrl;
  }, [searchQuery, previousDataSourceUrl, activeFilters, selectedProjectId, defaultUrl]);

  const fetchCategory = () => {
    if (!category_id || parsedCategoryId === 'none') {
      return;
    }
    store.dispatch(setIsLoading({ name: 'documentType', value: true }));
    networking.get(`/api/v1/document_types/${category_id}?appends[]=category_tags&per_page=9999`)
      .then((response: E2U.V1.Response.Success<E2U.V1.Models.Type>) => {
        store.dispatch(setSelectedCategory(response.data.data));
      })
      .catch((error: E2U.V1.Response.Error) => {
        toasters.error(t('Document category not found'));
        Sentry.captureException(error);
      })
      .finally(() => {
        store.dispatch(setIsLoading({ name: 'documentType', value: false }));
      });
  };

  const handleSearch = (query: string, currentUrl: SourceUrlProps | string) => {
    if (!query.length) {
      if (!searchQuery.length) {
        return;
      }

      setSearchQuery(query);
    } else {
      setSearchQuery(query);
    }

    if (typeof currentUrl === 'string') {
      setPreviousDataSourceUrl(undefined);
      return;
    }
    setPreviousDataSourceUrl(currentUrl);
  };

  useIonViewDidEnter(() => {
    fetchCategory();
  });

  useEffect(() => {
    if (forceReloadDocument) {
      fetchCategory();
      store.dispatch(setForceReloadDocument(false));
    }
  }, [forceReloadDocument]);

  useIonViewWillLeave(() => {
    setSearchQuery('');
    setActiveFilters({});
    store.dispatch(setSelectedCategory(undefined));
  });

  useEffect(() => {
    store.dispatch(setShouldRefetchDocuments(undefined));
  }, [selectedProjectId]);

  return (
    <SideMenuV2Layout title={category?.name ?? t('Uncategorized')} showDocumentActionsButton={true} paddedPage={false}>
      {
        (isLoadingType || !selectedProjectId)
          ? (
            <SkeletonTextThreeLines />
          )
          : (
            <div className='ion-padding'>
              <IonGrid>
                <IonRow>
                  <IonCol>
                    {isDesktop && <IonGrid>
                      <IonRow>
                        <IonCol>
                          <BigUp.Title label={category?.name} />
                        </IonCol>
                      </IonRow>
                    </IonGrid>}
                    {dataSourceUrl && <BigUp.Table
                      timestamp={lastReloadTs}
                      sourceUrl={dataSourceUrl}
                      columns={
                        [{
                          key: 'name',
                          label: t('Name'),
                          flex: true,
                          alignment: 'left',
                          sizes: {
                            xs: '7',
                            sm: '7',
                            md: '8',
                            lg: '9',
                            xl: '9'
                          },
                          sortable: true,
                          body: (row: E2U.V1.Models.Type | E2U.V1.Models.File) => {
                            return 'filetype' in row
                              ? <FilePreviewColumn attributes={row} />
                              : <span>{row.name}</span>;
                          },
                        }, {
                          key: 'files_count',
                          flex: true,
                          label: t('Count'),
                          alignment: 'right',
                          sizes: {
                            xs: '5',
                            sm: '5',
                            md: '4',
                            lg: '3',
                            xl: '3'
                          },
                          sortable: true
                        }]
                      }
                      filtersCustom={
                        <MultiSelectorGroup callbacks={{
                          onSelectionChange: (selection: MultiSelectorGroupValuesProps) => {
                            if (!Object.keys(selection).length && !activeFilters.length) {
                              setPreviousDataSourceUrl(defaultUrl);
                              setActiveFilters({});
                              return;
                            }

                            const filters = Object.entries(selection).reduce((acc, [key, value]) => {
                              return {
                                ...acc,
                                [`filters[${key}]`]: value.join(','),
                              };
                            }, {});

                            setActiveFilters(filters);
                          }
                        }}>
                          <DocumentActivityCodeFilter sourceUrl={{
                            url: `/api/v1/projects/${selectedProjectId}/documents/filters`,
                            args: {
                              'only[]': 'activity_codes',
                              'filters[categories]': parsedCategoryId,
                            },
                          }} />
                          <DocumentExtensionCodeFilter sourceUrl={{
                            url: `/api/v1/projects/${selectedProjectId}/documents/filters`,
                            args: {
                              'only[]': 'extensions',
                              'filters[categories]': parsedCategoryId,
                            },
                          }} />
                        </MultiSelectorGroup>
                      }
                      collapsible={{
                        key: 'files',
                        rowIsEnabled: (row: E2U.V1.Models.Document) => (row.files?.length ?? 0) > 0,
                        componentKey: 'files',
                        component: LatestFileList,
                      }}
                      callbacks={{
                        onSearch: handleSearch,
                        onRowClick: (row: E2U.V1.Models.Document) => {
                          router.push(getDocumentRoute(row, selectedProjectId ?? ''));
                        },
                        onRenderComplete: (rows: E2U.V1.Models.Document[], setRows) => {
                          setRows(rows.map((row) => {
                            return { ...row, files_count: t('{count} files', { count: row.files?.length ?? 0 }) };
                          }));
                        }
                      }}
                    />}
                  </IonCol>
                </IonRow>
              </IonGrid>
            </div>
          )
      }
    </SideMenuV2Layout>
  );
};

export default DocumentCategoryPage;
