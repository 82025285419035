import { IonCol, IonGrid, IonRow, useIonViewWillEnter } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useState } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Section from './Sections';
import Sections from './Sections/Sections';
import { networking } from '../../../api/networking';
import BigUp from '../../../components/UI';
import toasters from '../../../components/UI/Toasts';
import { useAppSelector } from '../../../hooks';
import useCameraUpload from '../../../hooks/useCameraUpload';
import useFileUpload from '../../../hooks/useFileUpload';
import i18n from '../../../i18n';

export type WorkPreparationRows = {
  id: string;
  active: boolean;
  description: string;
  files: E2U.V1.Models.File[];
};

export interface WorkPreparation {
  activity_codes: [];
  work_operation: string;
  description: string;
  rows: WorkPreparationRows[];
}

const defaultValues: WorkPreparation = {
  activity_codes: [],
  work_operation: '',
  description: '',
  rows: [],
};

interface PreparationFormProps {
  refetchTable: () => void;
}

const validationMessage = (error: any) => {
  if (error.data.work_operation) return i18n.t('Missing work operation');
  if (error.data.activity_codes) return i18n.t('Missing activity code');
  if (error.data.rows) return i18n.t('You need to add rows');
  if (error.data) return i18n.t('Note missing on required rows');
  return error.message;
};

const PreparationForm: React.FC<PreparationFormProps> = (props) => {
  const methods = useForm<WorkPreparation>({ defaultValues });
  const [sections, setSections] = useState<any[]>([]);

  const project = useAppSelector(state => state.project.selectedProject);
  const { t } = useTranslation();
  const cameraProps = useCameraUpload();
  const fileProps = useFileUpload();

  const onSubmit = (data: WorkPreparation) => {
    const networkRequest = networking.post(`/api/v1/projects/${project?.id}/work_preparation_protocol`, data);
    toasters.promiseToast(networkRequest, {
      pending: { message: t('Submitting preparation'), },
      success: { message: t('Preparation created successfully'), },
      error: { message: t('Couldn\'t submit preparation'), }
    })
      .then((response) => {
        const finishedPromises: Promise<any>[] = [];
        (response.data.data.entries ?? []).forEach((entry: any) => {
          entry.reports.forEach((report: any) => {
            finishedPromises.push(
              Promise.allSettled([
                cameraProps.uploadSelectedPhotos('/api/v1/work_preparations/protocol_entry_reports', report.id || ''),
                fileProps.uploadSelectedFiles('/api/v1/work_preparations/protocol_entry_reports', report.id || ''),
              ])
            );
          });
        });
        props.refetchTable();
        return Promise.all(finishedPromises);
      })
      .then(() => {
        methods.reset();
      })
      .catch((error) => {
        toasters.createToast({
          message: validationMessage(error.response.data),
          iconColour: 'danger',
          background: 'var(--ion-color-light)'
        }, 'error');
      });
  };

  const getAllPreparationsForProject = () => {
    if (!project?.id) return;
    networking.get(`/api/v1/projects/${project?.id}/work_preparation_protocols`)
      .then(response => {
        setSections(response.data.data);
      });
  };

  useIonViewWillEnter(() => {
    getAllPreparationsForProject();
  }, []);

  return (
    <FormProvider {...methods}>
      <form onSubmit={methods.handleSubmit(onSubmit)}>
        <Section.Project />
        <Section.Description />
        <Sections cameraProps={cameraProps} fileProps={fileProps} sections={sections} />
        <IonGrid>
          <IonRow className='ion-justify-content-center'>
            <IonCol size={'6'}>
              <BigUp.Buttons.Primary
                expand='block'
                disabled={!project || !methods.formState.isValid || methods.formState.isSubmitting}
                size='default'
                shape='round'
                title={t('Sign')}
                type='submit'
              />
            </IonCol>
          </IonRow>
        </IonGrid>
      </form>
    </FormProvider>
  );
};

export default PreparationForm;
