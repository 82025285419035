import { IonNav } from '@ionic/react';
import { useEffect, useRef } from 'react';

import ContentWrapper from '../ContentWrapper';
import Categories from './Categories';
import type { NavigationProps } from './types';

const BigUpTab: React.FC<NavigationProps> = (props) => {
  const root = useRef<HTMLIonNavElement>(null);

  useEffect(() => {
    if (root.current) {
      root.current.setRoot(() => <Categories navigation={root.current!} {...props} />);
    }
  }, []);

  return (
    <ContentWrapper>
      <IonNav ref={root} />
    </ContentWrapper>
  );
};

export default BigUpTab;
