import { IonCol, IonGrid, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useMemo, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

import ZoomableBase from './ZoomableBase';
import { useAppSelector } from '../../../../hooks';

interface PdfPreviewProps {
  src: string;
  file: E2U.V1.Models.File
}

pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

const options = {
  cMapUrl: '/cmaps/',
  standardFontDataUrl: '/standard_fonts/',
};

const PdfPreview: React.FC<PdfPreviewProps> = ({ file, src }) => {
  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const [numPages, setNumPages] = useState<number>();
  const [amountPagesLoaded, setAmountPagesLoaded] = useState(0);
  const allPagesLoaded = useMemo(() => amountPagesLoaded === numPages, [amountPagesLoaded, numPages]);

  const onDocumentLoadSuccess = (numPages: number) => {
    setNumPages(numPages);
  };

  return (
    <ZoomableBase
      isLoading={!src || !file}
      amountOfPages={numPages ?? 0}
      allPagesLoaded={allPagesLoaded}
    >
      {(src && src.length) && (
        <Document
          file={src}
          options={options}
          onLoadSuccess={({ numPages }) => onDocumentLoadSuccess(numPages)}
        >
          <IonGrid>
            <IonRow>
              {numPages && Array.from({ length: numPages }).map((_, index) => (
                <IonCol
                  className={'zoomable-page'}
                  key={index}
                  {...(!isDesktop ? { size: 'auto' } : {})}
                >
                  <Page
                    pageNumber={index + 1}
                    renderTextLayer={false}
                    renderAnnotationLayer={false}
                    devicePixelRatio={4}
                    onLoadSuccess={() => {
                      setAmountPagesLoaded(index + 1);
                    }}
                  />
                </IonCol>
              ))}
            </IonRow>
          </IonGrid>
        </Document>
      )}
    </ZoomableBase>
  );
};

export default PdfPreview;
