import { IonButton, IonButtons, IonContent, IonHeader, IonIcon, IonModal, IonTitle, IonToolbar, isPlatform, useIonRouter, useIonViewWillEnter } from '@ionic/react';
import { addSharp, close } from 'ionicons/icons';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BigUp from '../../../components/UI';
import type { TableHeaderProps } from '../../../components/UI/Table/Header/TableHeader';
import { useAppSelector } from '../../../hooks';
import PreparationForm from '../PreparationForm';
import { createTableColumns, createTableProps } from './helpers';
import styles from './Preparations.module.scss';

const WorkPreparations: React.FC = () => {
  const { t } = useTranslation();
  const router = useIonRouter();
  const [formOpen, setFormOpen] = useState(false);
  const [lastControlFetch, setLastControlFetch] = useState<number>(Date.now());
  const user = useAppSelector((state) => state.authentication.user);
  const project = useAppSelector((state) => state.project.selectedProject);

  const isDesktop = isPlatform('desktop');
  const device = isDesktop ? 'desktop' : 'mobile';

  const sourceUrl = useMemo(
    () =>
      project?.id
        ? `/api/v1/projects/${project.id}/work_preparation_protocols`
        : `/api/v1/users/${user?.id}/work_preparation_protocols`,
    [project?.id, user?.id]
  );

  const tableHeaderProps: TableHeaderProps = useMemo(
    () => ({
      title: project?.id
        ? t('Work preparations for {project}', { project: project.name })
        : t('Your work preparations'),
      button: {
        size: 'small',
        title: t('Create new'),
        icon: { icon: addSharp },
        onClick: () => setFormOpen(true),
      },
    }),
    [project?.id, project?.name, t]
  );

  const tableColumns = useMemo(() => createTableColumns(t), [t]);
  const tableProps = useMemo(() => createTableProps(
    lastControlFetch,
    sourceUrl,
    tableColumns,
    router
  ), [lastControlFetch, router, sourceUrl, tableColumns, t]
  );

  const handleSubmit = useCallback(() => {
    setLastControlFetch(Date.now());
    setFormOpen(false);
  }, []);

  useIonViewWillEnter(() => {
    if (project?.id) setLastControlFetch(Date.now());
  }, [project?.id]);

  return (
    <div className={`${styles.container} ion-padding-top ion-margin-top`}>
      <BigUp.TableHeader {...tableHeaderProps} />
      <BigUp.Table {...tableProps} />
      <IonModal
        isOpen={formOpen}
        onDidDismiss={() => setFormOpen(false)}
        className={styles[`preparation-form-modal--${device}`]}
        style={{ '--background': 'var(--ion-color-light-shade)' }}
      >
        <IonHeader mode="ios" className="ion-no-border">
          <IonToolbar mode="ios" style={{ padding: '8px' }}>
            <IonTitle>{t('Work preparation')}</IonTitle>
            <IonButtons slot="end">
              <IonButton color="dark" onClick={() => setFormOpen(false)}>
                <IonIcon size="large" icon={close} />
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent
          className="ion-padding"
          style={{ '--background': 'var(--ion-color-light-shade)' }}
        >
          <PreparationForm refetchTable={handleSubmit} />
        </IonContent>
      </IonModal>
    </div>
  );
};

export default WorkPreparations;
