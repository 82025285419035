import type { InputProps } from '../../../../components/UI/Inputs/Input.interface';
import i18n from '../../../../i18n';

type Alignment = 'end' | 'start' | 'center' | 'space-between' | 'space-around' | 'space-evenly' | 'stretch' | 'baseline' | undefined;
export const projectInputs: InputProps<any>[] = [
  {
    label: i18n.t('Company'),
    placeholder: i18n.t('Company'),
    disabled: true,
    labelPlacement: 'start',
    register: 'company',
    alignment: 'end',
    validation: {
      required: false,
    },
  },
  {
    label: i18n.t('Project'),
    placeholder: i18n.t('Project'),
    labelPlacement: 'start',
    disabled: true,
    alignment: 'start',
    register: 'project',
    validation: {
      required: false,
    },
  },
  {
    label: i18n.t('Contact person'),
    placeholder: i18n.t('Contact person'),
    labelPlacement: 'start',
    register: 'contact_person',
    alignment: 'end',
    validation: {
      required: false,
    },
  },
  {
    label: i18n.t('Work activities'),
    labelPlacement: 'start',
    register: 'work_operation',
    alignment: 'end',
    placeholder: i18n.t('Work activities'),
    validation: {
      required: '*',
    },
  },
];
