
import { IonButton } from '@ionic/react';
import React from 'react';

import styles from './CardButton.module.scss';
import CategoryTitleColumn from '../../../pages/Document/Category/CategoryTitleColumn';
interface CardButtonProps {
  type: any;
  onClick: () => void;
  title?: string;
  subtitle?: string;
}

const CardButton: React.FC<CardButtonProps> = (props) => {
  return (
    <div className={styles['card-button']}>
      <IonButton
        fill="clear"
        onClick={props.onClick}
        className={styles['card-button__button']}
      >
        <CategoryTitleColumn thumbnail attributes={props.type} />
      </IonButton>
      <div className={styles['card-button__label']}>
        <p className={styles['card-button__label-text']}>
          {props.title ? props.title : props.type.name}
        </p>
        <p className={styles['card-button__label-subtext']}>
          {props.subtitle}
        </p>
      </div>
    </div>
  );
};

export default CardButton;
