import { IonBreadcrumb, IonBreadcrumbs, IonButton, IonCol, IonGrid, IonIcon, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { chevronBack } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { marginTop } from './constants';
import { BigUp } from '../../../..';
import { useAppSelector } from '../../../../../../hooks';
import { useClearFileUploadReducers } from '../../../../../../hooks/useCreateAndAttachFilesOnCategory';
import useFilePreviews from '../../../../../../hooks/useFilePreview';
import { setFiles, setSearch, setSelectedFiles } from '../../../../../../reducers/files';
import store from '../../../../../../store';
import { formatToNiceDate } from '../../../../../../tools/formatDates';
import ContentWrapper from '../ContentWrapper';
import type { NavigationProps } from './types';
import { networking } from '../../../../../../api/networking';

const Files: React.FC<NavigationProps> = ({ navigation, ...props }) => {
  const [shouldFetch, setShouldFetch] = useState(true);

  const fileReducers = useAppSelector((state) => {
    return {
      search: state.fileSelection.search,
      toggle: state.fileSelection.toggleListView,
      selectedDocument: state.fileSelection.selectedDocument,
      selectedFiles: state.fileSelection.selectedFiles,
    };
  });
  const [fileList, setFileList] = useState<E2U.V1.Models.File[]>([]);
  const { t } = useTranslation();
  const clearReducers = useClearFileUploadReducers();
  const selectedProjectId = useAppSelector((state) => state.project.selectedProject?.id);
  const { fileUrls, loadingPreview } = useFilePreviews(fileList);

  const checkIfSelected = (file: E2U.V1.Models.File) => {
    return fileReducers.selectedFiles?.some(selectedFile => selectedFile.id === file.id);
  };

  const handleItemPress = (file: E2U.V1.Models.File) => {
    const isSelected = fileReducers.selectedFiles?.some(selectedFile => selectedFile.id === file.id);
    const updatedFiles = isSelected
      ? fileReducers.selectedFiles?.filter(selectedFile => selectedFile.id !== file.id)
      : [...(fileReducers.selectedFiles || []), file];
    store.dispatch(setSelectedFiles(updatedFiles));
  };

  const getFilesInDocument = () => {
    if (!selectedProjectId) return;
    networking.get(`/api/v1/documents/${fileReducers.selectedDocument?.id}/files?search=${fileReducers.search}`)
      .then((response) => {
        setFileList(response.data.data.records);
      });
  };

  const navigateBack = () => {
    navigation?.pop()
      .then(() => {
        clearReducers();
      });
  };

  useEffect(() => {
    store.dispatch(setFiles(fileReducers.selectedDocument?.files ?? []));
  }, [fileReducers.selectedDocument]);

  useEffect(() => {
    if (shouldFetch && navigation?.getActive()) {
      getFilesInDocument();
    }
  }, [navigation, selectedProjectId, fileReducers.search, shouldFetch]);

  return (
    <ContentWrapper>
      <IonBreadcrumbs style={{ marginTop }} className='ion-padding-top'>
        <BigUp.Breadcrumbs.Breadcrumb label={fileReducers.selectedDocument?.name} onClick={navigateBack} />
        <BigUp.Breadcrumbs.Breadcrumb active label={t('Files')} onClick={() => undefined} />
      </IonBreadcrumbs>

      <IonGrid className='ion-no-padding' style={{ marginBottom: 100 }}>
        <IonRow>
          {fileList.map((file) => {
            return (
              fileReducers.toggle
                ? (
                  <IonCol size={'4'} sizeSm='3' key={file.id}>
                    <BigUp.Preview.CheckboxPreview
                      fileType={file.type as any}
                      type={file.filetype}
                      loading={loadingPreview}
                      handleCheckbox={() => { }}
                      image={{
                        src: fileUrls[file.id ?? ''],
                        alt: file.name,
                      }}
                      title={file.name}
                      subtitle={formatToNiceDate(file.updated_at)}
                      checked={checkIfSelected(file)}
                      onClick={() => handleItemPress(file)}
                    />
                  </IonCol>
                )
                : (
                  <IonCol size='12' key={file.id} className='ion-margin-top'>
                    <BigUp.Preview.PreviewItem
                      type={file.filetype}
                      lines='full'
                      subtitle={`${formatToNiceDate(file.updated_at)} `}
                      onClick={() => handleItemPress(file)}
                      previewLoading={loadingPreview}
                      fileUrl={fileUrls[file.id ?? '']}
                      fileName={file.name}
                      fileType={file.type as any}
                    >
                      <BigUp.Checkbox slot='end' handleCheckbox={() => { }} checked={checkIfSelected(file)} />
                    </BigUp.Preview.PreviewItem>
                  </IonCol>
                )
            );
          })}
        </IonRow>
      </IonGrid>
    </ContentWrapper>
  );
};

export default Files;
