
import BigUp from '..';

export const checkFileType = (name: string) => {
  let icon;
  const type = name.split('.').pop();
  switch (type) {
    case 'pdf':
      icon = BigUp.Icons.Svg.Extensions.Pdf;
      break;
    case 'csv':
      icon = BigUp.Icons.Svg.Extensions.Csv;
      break;
    case 'docx':
      icon = BigUp.Icons.Svg.Extensions.Docx;
      break;
    case 'doc':
      icon = BigUp.Icons.Svg.Extensions.Docx;
      break;
    case 'dvg':
      icon = BigUp.Icons.Svg.Extensions.Dvg;
      break;
    case 'dwg':
      icon = BigUp.Icons.Svg.Extensions.Dwg;
      break;
    case 'xls':
      icon = BigUp.Icons.Svg.Extensions.Xls;
      break;
    case 'xlsx':
      icon = BigUp.Icons.Svg.Extensions.Xlsx;
      break;
    case 'word':
      icon = BigUp.Icons.Svg.Extensions.Docx;
      break;
    default:
      icon = BigUp.Icons.Svg.Attachments;
      break;
  }

  return icon;
};
