import { IonItem, IonLabel, IonList } from '@ionic/react';

import CategoryTitleColumn from '../../../pages/Document/Category/CategoryTitleColumn';
import SkeletonItem from '../../SkeletonComponents/SkeletonItem';
import { marginTop } from '../Modal/FileUpload/Contents/BigUp/constants';

interface ListItemProps {
  type: any;
  onClick: () => void;
  title?: string;
  subtitle?: string;
}

export const DetailedListWrapper: React.FC<{
  children: React.ReactNode, loading: boolean
}> = ({
  children, loading
}) => {
  return (
    <IonList className='ion-padding-top' style={{ marginTop, marginBottom: '75px' }}>
      {loading && (
        <SkeletonItem
          amount={6}
          sizes={{
            sizeXs: '12',
            sizeMd: '12',
            sizeSm: '12',
            sizeLg: '12'
          }}
        />
      )}
      {children}
    </IonList>
  );
};

export const DetailedListItem: React.FC<ListItemProps> = (props) => {
  return (
    <IonItem
      detail
      className='ion-no-padding ion-margin-bottom'
      button={true}
      onClick={props.onClick}
    >
      <CategoryTitleColumn size='x-large' isBadge={false} attributes={props.type} />
      <IonLabel className='ion-text-nowrap'>
        <h2 style={{ fontWeight: 400, fontStyle: 'normal' }}>{props.title}</h2>
        <p>{props.subtitle}</p>
      </IonLabel>
    </IonItem>
  );
};
