import { IonIcon, IonItem, IonLabel, IonSkeletonText, IonText, IonThumbnail } from '@ionic/react';
import { trash } from 'ionicons/icons';
import { useState } from 'react';

import styles from './Item.module.scss';
import type { FilePreviewProps } from './types';
import { checkFileType } from './utils';

const PreviewItem: React.FC<FilePreviewProps> = ({
  children,
  endLabel,
  fileName,
  fileType,
  fileUrl,
  lines = 'full',
  onClick,
  onDelete,
  previewLoading,
  subtitle,
  ...props
}) => {
  const [_, setIsLoaded] = useState(false);

  const handleImageLoad = () => {
    setIsLoaded(true);
  };

  return (
    <IonItem
      onTouchStart={props.onTouchStart}
      onTouchEnd={props.onTouchEnd}
      {...(onClick && {
        onClick: () => onClick(),
        button: true,
        detail: false
      })
      }
      className={`${styles['preview-list-item']} ion-no-padding`} lines={lines} >
      {(previewLoading) && (
        <IonThumbnail slot="start">
          <IonSkeletonText animated={true}></IonSkeletonText>
        </IonThumbnail>
      )}

      {(fileType.includes('image') && !previewLoading) && (
        <IonThumbnail slot="start"
          style={{
            marginBottom: 8
          }}>
          <img alt={fileName} src={fileUrl} onLoadCapture={handleImageLoad}
          />
        </IonThumbnail>
      )}

      {(!fileType.includes('image') && !previewLoading) && (
        <IonIcon
          className={styles['fallback-icon']} slot='start'
          icon={checkFileType(fileType)}
          size='large'
          style={{
            marginBottom: 8
          }}
        />
      )}

      <div className={styles['row-container']}
        style={{
          marginBottom: 8
        }}>

        <IonLabel className='ion-text-nowrap ion-no-margin'>
          <h2>{fileName}</h2>
          <p>{subtitle}</p>
        </IonLabel>
        {endLabel && (
          <IonText>
            <IonLabel className={`${styles.label__end} ion-no-margin`}>
              {endLabel}
            </IonLabel>
          </IonText>
        )}
      </div>
      {onDelete && (
        <IonIcon
          onClick={() => onDelete()}
          icon={trash}
          slot="end"
        />
      )}
      {children}
    </IonItem>
  );
};

export default PreviewItem;
