import { IonBreadcrumb, IonBreadcrumbs, IonCol, IonGrid, IonRow } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BigUp from '../../../..';
import bigUpFolder from '../../../../../../components/icons/bigUpFolder/bigupFolder.svg';
import { useAppSelector } from '../../../../../../hooks';
import { useClearFileUploadReducers } from '../../../../../../hooks/useCreateAndAttachFilesOnCategory';
import useCrudResource from '../../../../../../hooks/useCrudResource';
import { setSearch, setSelectedDocument } from '../../../../../../reducers/files';
import store from '../../../../../../store';
import { formatToNiceDate } from '../../../../../../tools/formatDates';
import EmptyList from '../../../../EmptyList';
import ContentWrapper from '../ContentWrapper';
import Categories from './Categories';
import Files from './Files';
import type { NavigationProps } from './types';

const Documents: React.FC<NavigationProps> = ({ category, navigation, ...props }) => {
  const [documentsInCategoryType, setDocumentsInCategoryType] = useState([]);
  const selectedProjectId = useAppSelector((state) => state.project.selectedProject?.id);
  const fileReducers = useAppSelector((state) => {
    return {
      search: state.fileSelection.search,
      toggle: state.fileSelection.toggleListView
    };
  });

  const { fetch: crudFetch, } = useCrudResource();
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const clearReducers = useClearFileUploadReducers();

  const getDocumentTypes = () => {
    if (!selectedProjectId) return;
    setIsLoading(true);
    crudFetch(`/api/v1/projects/${selectedProjectId}/documents`, {
      per_page: '9999',
      direction: 'asc',
      search: fileReducers.search,
      'others[categories]': category?.id,
      filters: JSON.stringify([{
        field: 'document_type',
        value: category?.id
      }])
    })
      .then((response) => {
        setDocumentsInCategoryType(response.data.data.records);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const navigateBack = () => {
    navigation?.pop()
      .then(() => {
        clearReducers();
      });
  };

  const navigateToFiles = (document: E2U.V1.Models.Document) => {
    store.dispatch(setSelectedDocument(document));
    store.dispatch(setSearch(''));
    navigation?.push(() => (
      <Files category={category} navigation={navigation}{...props} />
    ));
  };

  useEffect(() => {
    getDocumentTypes();
  }, [navigation, selectedProjectId, fileReducers.search]);

  return (
    <ContentWrapper>

      <BigUp.ListComponent.DetailedListWrapper loading={isLoading}>
        {!isLoading && (
          <>
            <IonBreadcrumbs className='ion-no-padding'>
              <BigUp.Breadcrumbs.Breadcrumb
                label={t('BigUps unit')}
                onClick={navigateBack}
              />
              <BigUp.Breadcrumbs.Breadcrumb
                label={category?.name}
                active
                onClick={() => undefined}
              />
            </IonBreadcrumbs>
            {!fileReducers.toggle
              ? (
                documentsInCategoryType.map((document: E2U.V1.Models.Document & E2U.V1.Models.Document | any) => {
                  return (
                    document.files_count > 0 && (
                      <BigUp.ListComponent.DetailedListItem
                        key={document.id}
                        type={category}
                        onClick={() => navigateToFiles(document)}
                        title={document.name}
                        subtitle={
                          t('{date} - {files} files', {
                            date: formatToNiceDate(document.updated_at),
                            files: document.files_count
                          })
                        }
                      />
                    )
                  );
                })
              )
              : (
                <IonGrid>
                  <IonRow>
                    {documentsInCategoryType.map((document: E2U.V1.Models.Document & E2U.V1.Models.Document | any) => (
                      document.files_count > 0 && (
                        <IonCol size='4' sizeSm='3' key={document.id}>
                          <BigUp.Buttons.CardButton
                            title={document.name}
                            subtitle={`${document.files_count} files`}
                            type={category}
                            onClick={() => navigateToFiles(document)} />
                        </IonCol>
                      )
                    ))}
                  </IonRow>
                </IonGrid>
              )}
          </>
        )}
      </BigUp.ListComponent.DetailedListWrapper>
      {(documentsInCategoryType && documentsInCategoryType.length === 0) && !isLoading && (
        <EmptyList
          icon={{
            icon: bigUpFolder,
            size: 100,
          }}
          title={t('No files found in {category}', { category: category.name })}
          message={t('Try adjusting your search or check back later.')}
        />
      )}
    </ContentWrapper>
  );
};

export default Documents;
