import { IonBackButton, IonButtons, IonCol, IonContent, IonGrid, IonHeader, IonIcon, IonItem, IonList, IonPage, IonRow, IonText, IonTitle, IonToolbar, useIonViewWillEnter } from '@ionic/react';
import type { E2U } from '@techlove/easy2use-typings';
import type { ActivityCode } from '@techlove/easy2use-typings/src/V1/Models/ActivityCode';
import { checkmarkCircleOutline, closeCircleOutline } from 'ionicons/icons';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import styles from './PreparationDetails.module.scss';
import { networking } from '../../../api/networking';
import BigUp from '../../../components/UI';
import { useAppSelector } from '../../../hooks';
import useFetchProject from '../../../hooks/useFetchProject';
import { formatToYYYYMMDD } from '../../../tools/formatDates';

const PreparationDetails: React.FC = () => {
  const [protocol, setProtocol] = useState<any>();
  const [createdBy, setCreatedBy] = useState<E2U.V1.Models.User>();
  const [project, setProject] = useState<E2U.V1.Models.Project>();

  const { preparationId } = useParams<{ preparationId: string }>();
  const getProject = useFetchProject();
  const { t } = useTranslation();

  const getProtocolWithSections = () => {
    networking.get(`api/v1/work_preparations/protocols/${preparationId}/sorted_entries`)
      .then((protocolResponse) => {
        setProtocol(protocolResponse.data.data);
        return networking.get(`api/v1/users/${protocolResponse.data.data.created_by_id}`);
      })
      .then((userResponse) => {
        setCreatedBy(userResponse.data.data);

        if (!selectedProject?.id) {
          return networking.get(`/api/v1/projects/${protocol?.project_id}`)
            .then((projectResponse) => {
              setProject(projectResponse.data.data);
            });
        }
      })
      .catch((error) => {
        console.error('An error occurred:', error);
      });
  };

  useIonViewWillEnter(() => {
    getProtocolWithSections();
  }, [preparationId]);

  const selectedProject = useAppSelector((state) => state.project.selectedProject);

  const combineCodes = (codes: ActivityCode[]) => {
    return (codes ?? []).map((code: ActivityCode) =>
      code.code + ' ' + code.name).join(', ') || t('No activity codes added');
  };
  const cachedCodes = useMemo(() => combineCodes(protocol?.activity_codes), [protocol]);

  return (

    /*     <IonPage>
          <IonHeader className='ion-no-border'>
            <IonToolbar className='ion-padding-top'>
              <IonButtons slot="start">
                <IonBackButton defaultHref='/tools' />
              </IonButtons>
              <IonTitle>{protocol?.activity}</IonTitle>
            </IonToolbar>
          </IonHeader>
          <IonContent className={styles['protocol-content-wrapper']}> */
    <>
      <div className={styles['protocol-details-container']}>
        <div className={`ion-padding ${styles['protocol-list-section']}`}>
          <IonGrid className='ion-no-padding ion-padding-horizontal'>
            <IonRow className='ion-justify-content-between ion-no-padding'>
              <IonCol size='auto' className='ion-no-padding'>
                <BigUp.Label.Thick label={t('Created by: {first_name} {last_name}', 'Created by: {first_name} {last_name}', {
                  first_name: createdBy?.first_name,
                  last_name: createdBy?.last_name,
                })}></BigUp.Label.Thick>
              </IonCol>
              <IonCol size='auto'>
                <BigUp.Label.Regular label={formatToYYYYMMDD(protocol?.created_at)}></BigUp.Label.Regular>
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonList className='ion-no-padding ion-padding-horizontal'>
            <IonItem className='ion-margin-bottom ion-no-padding' lines='none'>
              <IonText>
                <BigUp.Label.Thick color={'medium'} label={t('Company')}></BigUp.Label.Thick>
                <BigUp.Label.Regular className='ion-no-margin' label={selectedProject?.name ?? project?.name}></BigUp.Label.Regular>
              </IonText>
            </IonItem>
            <IonItem className='ion-margin-bottom ion-no-padding' lines='none'>
              <IonText>
                <BigUp.Label.Thick color={'medium'} label={t('Contact person')}></BigUp.Label.Thick>
                <BigUp.Label.Regular className='ion-no-margin' label={project?.name}></BigUp.Label.Regular>
              </IonText>
            </IonItem>
            <IonItem className='ion-margin-bottom ion-no-padding' lines='none'>
              <IonText>
                <BigUp.Label.Thick color={'medium'} label={t('Project')}></BigUp.Label.Thick>
                <BigUp.Label.Regular className='ion-no-margin' label={project?.name}></BigUp.Label.Regular>
              </IonText>
            </IonItem>
            <IonItem className='ion-margin-bottom ion-no-padding' lines='none'>
              <IonText>
                <BigUp.Label.Thick color={'medium'} label={t('Activity Codes')}></BigUp.Label.Thick>
                <BigUp.Label.Regular className='ion-no-margin' label={cachedCodes}></BigUp.Label.Regular>
              </IonText>
            </IonItem>
            <IonItem className='ion-margin-bottom ion-no-padding' lines='none'>
              <IonText>
                <BigUp.Label.Thick color={'medium'} label={t('Work operation')}></BigUp.Label.Thick>
                <BigUp.Label.Regular className='ion-no-margin' label={protocol?.work_operation ?? t('No work operation added')}></BigUp.Label.Regular>
              </IonText>
            </IonItem>
          </IonList>
        </div>
        <IonGrid className='ion-no-padding ion-padding-horizontal ion-margin-top' style={{ width: '100%', maxWidth: '500px' }}>
          <IonRow className='ion-justify-content-start'>
            <IonCol size='auto'>
              <BigUp.Label.Thick className='ion-margin-start' color={'medium'} label={t('Description')}></BigUp.Label.Thick>
            </IonCol>
          </IonRow>
        </IonGrid>
        <div className={`ion-padding ${styles['protocol-list-section']}`}>
          <IonList className='ion-no-padding ion-padding-horizontal'>
            <IonItem className='ion-margin-bottom ion-no-padding' lines='none'>

              <BigUp.Label.Regular className='ion-no-margin' label={protocol?.description ?? t('No description added')}></BigUp.Label.Regular>

            </IonItem>
          </IonList>
        </div>
        {protocol && protocol.sections.map((section: any, i: number) => (
          <>
            <IonGrid className='ion-no-padding ion-padding-horizontal ion-margin-top' style={{ width: '100%', maxWidth: '500px' }}>
              <IonRow className='ion-justify-content-start'>
                <IonCol size='auto'>
                  <BigUp.Label.Thick className='ion-margin-vertical' color={'medium'} label={section.name}></BigUp.Label.Thick>
                </IonCol>
              </IonRow>
            </IonGrid>
            <div className={`ion-padding ${styles['protocol-list-section']}`} key={i} >
              {(!section.categories || section.categories.length === 0) && (
                <IonList className='ion-no-padding ion-padding-horizontal'>
                  <IonItem className='ion-no-padding' lines='none'>
                    <IonText>
                      <BigUp.Label.Regular className='ion-no-margin' label={t('No categories for this section')}></BigUp.Label.Regular>
                    </IonText>
                  </IonItem>
                </IonList>
              )}
              {(section.categories && section.categories.length > 0) && section.categories.map((category: any, index: number) => {
                return (
                  <>
                    {(category.name) && (
                      <BigUp.Label.Thick className='ion-margin-horizontal' color={'medium'} label={category.name}></BigUp.Label.Thick>
                    )}
                    {(!category.entries || category.entries.length === 0) && (
                      <>
                        <IonItem className=' ion-no-padding' lines='none'>
                          <IonText>
                            <BigUp.Label.Regular className='ion-no-margin' label={t('No entries for this category')}></BigUp.Label.Regular>
                          </IonText>
                        </IonItem>
                      </>
                    )}
                    {category.entries.map((row: any, index: number) => {
                      return (
                        <IonList key={index} className='ion-no-padding ion-padding-horizontal'>
                          <IonItem className=' ion-no-padding' lines='none'>
                            <IonText>
                              <BigUp.Label.Regular className='ion-no-margin' label={row.row_name}>
                                <IonIcon icon={row.active ? checkmarkCircleOutline : closeCircleOutline}
                                  color={row.active ? 'success' : 'danger'}
                                  size="extra-small"
                                  className='ion-margin-start'
                                ></IonIcon>
                              </BigUp.Label.Regular>
                            </IonText>
                          </IonItem>
                          {row.reports.map((report: any, index: number) => {
                            return (
                              <>
                                <IonItem key={index} className=' ion-no-padding' lines='none'>
                                  <IonText>
                                    <BigUp.Label.Regular className='ion-no-margin' label={t('Description: {description}', 'Description: {description}', { description: report.description })}></BigUp.Label.Regular>
                                  </IonText>
                                </IonItem>

                                {(report.files.length !== 0) &&
                                  (report.files.map((file: any, index: number) => {
                                    return (
                                      <>
                                        <IonItem className=' ion-no-padding' lines='none'>
                                          <IonText>
                                            <BigUp.Label.Thick className='ion-no-margin' color={'medium'} label={t('Files')}></BigUp.Label.Thick>
                                            <div key={index} className=' ion-no-padding'>
                                              <BigUp.Label.Regular className='ion-no-margin' label={file.name}></BigUp.Label.Regular>
                                            </div>
                                          </IonText>
                                        </IonItem>
                                      </>
                                    );
                                  })
                                  )}
                              </>
                            );
                          }

                          )}
                        </IonList>
                      );
                    })}

                  </>
                );
              }
              )}
            </div>
          </>
        )

        )}
      </div>

    </>
    /*       </IonContent>
        </IonPage> */
  );
};

export default PreparationDetails;
