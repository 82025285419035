import classNames from 'classnames';
import React from 'react';

import styles from './Badge.module.scss';

export interface BadgeProps {
  active?: boolean;
  size?: 'small' | 'normal' | 'x-large';
}

const Badge: React.FC<React.PropsWithChildren<BadgeProps>> = (props) => {
  const { active, children, size } = props;
  return (
    <div className={classNames(
      styles['icon-badge'],
      active && styles['icon-badge--active'],
      size === 'small' && styles['icon-badge--small'],
      size === 'x-large' && styles['icon-badge--x-large']
    )}>
      {children}
    </div>
  );
};

export default Badge;
