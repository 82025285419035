import { IonButton, IonButtons, IonCol, IonGrid, IonHeader, IonIcon, IonInput, IonLabel, IonRow, IonSearchbar, IonTitle, IonToolbar, useIonAlert, useIonViewWillEnter } from '@ionic/react';
import { addCircle, removeCircleSharp } from 'ionicons/icons';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { networking } from '../../../api/networking';
import BigUp from '../../../components/UI';
import toasters from '../../../components/UI/Toasts';
import { useAppSelector } from '../../../hooks';

interface AssessmentProps {
  dismiss: () => void;
  sectionId: string | undefined;
}
const AssessmentList: React.FC<AssessmentProps> = (props) => {
  const [isEdit, setIsEdit] = useState(false);
  const [section, setSection] = useState<any>(null);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [newRowName, setNewRowName] = useState<{ [key: string]: string }>({});
  const [newCategoryName, setNewCategoryName] = useState<string>();
  const [newCategoryOrder, setNewCategoryOrder] = useState<number>();
  const [search, setSearch] = useState('');
  const [alert] = useIonAlert();
  const { t } = useTranslation();

  const selectedProject = useAppSelector(state => state.project.selectedProject);

  const fetchAssessmentList = () => {
    networking.get(`/api/v1/work_preparations/sections/${props.sectionId}?search=${search}&with=categories.rows`)
      .then(res => {
        setSection(res.data.data);
        const activeRows = res.data.data.categories.flatMap((category: any) =>
          category.rows.filter((row: any) => row.is_active).map((row: any) => row.id)
        );

        if (selectedRows.length !== 0) {
          setSelectedRows(selectedRows);
        } else {
          setSelectedRows(activeRows);
        }
      });
  };
  const saveSelectedRows = (data: any) => {
    networking.post(`/api/v1/projects/${selectedProject?.id}/work_preparation_users_choices`, data)
      .then(res => {
        props.dismiss();
      });
  };

  const confirmDeleteRow = (id: string, name: string) => {
    alert({
      header: t('Delete row'),
      message: t('Are you sure you want to delete row: {row}', 'Are you sure you want to delete row: {row}', {
        row: name
      }),
      buttons: [
        {
          text: t('Cancel'),
          role: 'cancel'
        },
        {
          text: t('Delete'),
          handler: () => deleteRow(id)
        }
      ]
    });
  };

  const handleSelectedRows = (row: any) => {
    setSelectedRows(prevState => {
      if (prevState.includes(row.id)) {
        return prevState.filter((id: any) => id !== row.id);
      } else {
        return [...prevState, row.id];
      }
    }
    );
  };

  const handleInputChange = (value: string, id: string) => {
    setNewRowName(prev => ({
      ...prev,
      [id]: value,
    }));
  };

  const createNewRow = (categoryId: string) => {
    networking.post(`/api/v1/work_preparations/rows`, {
      category_id: categoryId,
      name: newRowName[categoryId]
    })
      .then(response => {
        fetchAssessmentList();
        toasters.createToast({
          message: t('Created new row: {row}', 'Created new row: {row}', { row: response.data.data.name }),
          background: 'var(--ion-color-light)'
        }, 'success');
        handleInputChange('', categoryId);
      });
  };

  const deleteRow = (rowId: string) => {
    networking.delete(`/api/v1/work_preparations/rows/${rowId}`)
      .then(() => {
        fetchAssessmentList();
        toasters.createToast({
          message: t('Deleted row'),
          background: 'var(--ion-color-light)'
        }, 'success');
      });
  };

  const createCategory = () => {
    networking.post(`/api/v1/work_preparations/categories`, {
      section_id: section.id,
      name: newCategoryName,
      order: newCategoryOrder,
    })
      .then(response => {
        fetchAssessmentList();
        toasters.createToast({
          message: t('Created new category: {category}', 'Created new category: {category}', { category: response.data.data.name }),
          background: 'var(--ion-color-light)'
        }, 'success');
        setNewCategoryName('');
        setNewCategoryOrder(undefined);
      })
      .catch(() => {
        if (typeof newCategoryOrder !== 'number') {
          toasters.createToast({
            message: t('Order must be a number'),
            background: 'var(--ion-color-light)'
          }, 'error');
        } else {
          toasters.createToast({
            message: t('Could not create category'),
            background: 'var(--ion-color-light)'
          }, 'error');
        }
      });
  };

  useIonViewWillEnter(() => {
    fetchAssessmentList();
  }, [selectedProject?.id]);

  useEffect(() => {
    if (search === null) return;
    fetchAssessmentList();
  }, [search]);

  return (
    <>
      <IonHeader className='ion-no-border'>
        <IonToolbar mode='ios' style={{ padding: '8px' }}>
          <IonButtons slot='start'>
            <IonButton onClick={() => props.dismiss()}>
              {t('Cancel')}
            </IonButton>
          </IonButtons>
          <IonTitle>
            {selectedRows.length === 0
              ? t('Select assessments')
              : t('Select {count} assessments', {
                count: selectedRows.length
              })}
          </IonTitle>
          <IonButtons slot='end'>
            <IonButton onClick={() => setIsEdit(!isEdit)} color='secondary'>
              {t('Edit')}
            </IonButton>
          </IonButtons>
        </IonToolbar>
        <IonToolbar>
          <IonSearchbar
            mode='ios'
            style={{
              '--background': 'var(--ion-color-light-shade)',
            }}
            value={search}
            onIonInput={(e) => setSearch(e.detail.value!)}
          />
        </IonToolbar>
      </IonHeader>
      <div className='ion-padding'>
        {section && section.categories.map((category: any, index: number) => (
          <BigUp.ItemList
            key={index}
            header={{ title: category.name }}
            extraRow={{
              hasExtraRow: true,
              item: { detail: false, button: false },
              content: (
                <>
                  <IonIcon className='ion-no-margin ion-margin-end' aria-hidden='true' icon={addCircle} color='success' slot='start'></IonIcon>
                  <IonInput
                    value={newRowName[category.id] || ''}
                    onIonInput={(e: any) => handleInputChange(e.target.value, category.id)}
                    labelPlacement='start'
                    placeholder={t('Create a new')}
                  />
                  <BigUp.Buttons.Regular
                    disabled={!newRowName[category.id]}
                    title={t('Create')}
                    onClick={() => createNewRow(category.id)}
                    fill='clear'
                    color='success'
                    slot='end'
                  />
                </>
              )
            }}
            items={category.rows.map((row: any) => ({
              id: row.id,
              content: !isEdit
                ? ItemCheckbox(row, selectedRows.includes(row.id), () => handleSelectedRows(row))
                : ItemIsEdit(row, () => confirmDeleteRow(row.id, row.name))
            }))}
          >
          </BigUp.ItemList>
        ))
        }
      </div>
      {isEdit && <div className='ion-padding-horizontal'>
        <BigUp.ItemList
          header={{ title: t('Create new category') }}
          extraRow={{
            hasExtraRow: true,
            item: { detail: false, button: false },
            content: (
              <>
                <IonInput
                  class='ion-padding-horizontal'
                  value={newCategoryName ?? ''}
                  onIonInput={(name: any) => setNewCategoryName(name.target.value)}
                  placeholder={t('Name')}
                />
                <IonInput
                  class='ion-padding-horizontal'
                  style={{
                    textAlign: 'end',
                    width: 'auto'
                  }}
                  value={newCategoryOrder ?? ''}
                  onIonInput={(order: any) => setNewCategoryOrder(order.target.value)}
                  inputMode='numeric'
                  type='number'
                  placeholder={t('Order')}
                />
                <BigUp.Buttons.Regular
                  disabled={!newCategoryName}
                  title={t('Create')}
                  onClick={() => createCategory()}
                  fill='clear'
                  color='success'
                />
              </>
            )
          }}
          items={[]}
        />
      </div>}
      <IonGrid>
        <IonRow className='ion-justify-content-center'>
          <IonCol size='6' >
            <BigUp.Buttons.Primary
              expand='block'
              disabled={selectedRows.length === 0 || isEdit}
              size='default'
              title={t('Save')}
              onClick={() => {
                props.dismiss();
                saveSelectedRows({
                  rows: selectedRows.map((row) => {
                    return ({ id: row });
                  })
                });
              }}
            />
          </IonCol>
        </IonRow>
      </IonGrid>
    </>
  );
};

const ItemCheckbox = (item: any, isChecked: boolean, onChange?: () => void) => (
  <BigUp.Checkbox
    style={{
      '--checkbox-background-checked': 'var(--bigup-gradient-secondary)',
      '--border-color-checked': 'var(--color-white)'
    }}
    title={item.item}
    checked={isChecked}
    handleCheckbox={(e) => onChange && onChange()}
    labelProps={{ label: item.name }}
    labelPlacement='start'
  />
);

const ItemIsEdit = (item: any, deleteRow: () => void) => (
  <>
    <IonIcon size='default' icon={removeCircleSharp} color='danger' className='ion-margin-end' onClick={() => deleteRow()} />
    <IonLabel className='ion-no-margin'>{item.name}</IonLabel>
  </>
);

export default AssessmentList;
