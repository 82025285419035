import { IonCol, IonGrid, IonItem, IonRow, IonSelect, IonSelectOption, useIonRouter } from '@ionic/react';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import React, { useState } from 'react';
import type { FieldValues, SubmitHandler } from 'react-hook-form';
import { FormProvider, useForm } from 'react-hook-form';
import { useParams } from 'react-router';

import { networking } from '../../../../../api/networking';
import BigUp from '../../../../../components/UI';
import toasters from '../../../../../components/UI/Toasts';
import { useAppSelector } from '../../../../../hooks';
import useAcl from '../../../../../hooks/useAcl';
import i18n from '../../../../../i18n';
import { setSelectedPrecalculation } from '../../../../../reducers/precalculations';
import { setSelectedProject } from '../../../../../reducers/project';
import store from '../../../../../store';

const DuplicatePrecalculation: React.FC = () => {
  const router = useIonRouter();
  const { projects } = useAcl();
  const [projectSelect, setProjectSelect] = useState<E2U.V1.Models.Project>();
  const selectedProjectId = useAppSelector(state => state.project.selectedProject?.id);
  const selectedPrecalculation = useAppSelector((state) => state.precalculations.selectedPrecalculation);

  const { historicalPrecalculationId, precalculationId } = useParams<{
    precalculationId: string | undefined,
    historicalPrecalculationId: string | undefined
  }>();

  const methods = useForm({
    defaultValues: {
      name: '',
      description: '',
      project_id: '',
    },
  });

  const precalculationType = {
    precalculation: {
      endpoint: `/api/v1/precalculations/${precalculationId}/duplicate`,
      id: precalculationId,
    },
    historical: {
      endpoint: `/api/v1/precalculation_histories/${historicalPrecalculationId}/duplicate`,
      id: historicalPrecalculationId,
    }
  };

  const checkPrecalculationType = () => {
    return precalculationId ? precalculationType.precalculation : precalculationType.historical;
  };

  const submitDuplicate: SubmitHandler<FieldValues> = (data: FieldValues) => {
    toasters.promiseToast(networking.post(checkPrecalculationType().endpoint, {
      ...data,
      project_id: projectSelect ?? '',
    }), {
      pending: {
        message: i18n.t('Duplicating precalculation'),
      },
      success: {
        message: i18n.t('Precalculation duplicated'),
      },
      error: {
        message: i18n.t('Error duplicating precalculation'),
      },
    })
      .then(
        (response: E2U.V1.Response.Success<E2U.V1.Models.Precalculation>) => {
          methods.reset();
          setProjectSelect(undefined);
          if (response.data.data.project_id) {
            store.dispatch(setSelectedProject(undefined));
            networking.get(`/api/v1/projects/${response.data.data.project_id}`)
              .then((projectResponse) => {
                store.dispatch(setSelectedProject(projectResponse.data.data));
                router.push(`/financials/${response.data.data.project_id}/precalculations/${response.data.data.id}`);
              });
            return;
          }
          if (selectedProjectId) {
            router.push(`/financials/${selectedProjectId}/precalculations/${response.data.data.id}`);
          } else {
            router.push(`/financials/precalculations/${response.data.data.id}`);
          }
          store.dispatch(setSelectedPrecalculation(response.data.data));
        }
      )
      .catch((error) => {
        Sentry.captureException(error);
      });
  };

  return (
    <IonGrid className='ion-padding'>
      <IonRow>
        <IonCol>
          <BigUp.Title label={i18n.t('Duplicate Precalculation {name}', { name: selectedPrecalculation?.name })} />
        </IonCol>
      </IonRow>
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(submitDuplicate)}>
          <IonGrid>
            <IonRow>
              <IonCol>
                <BigUp.OutlinedInput
                  name='name'
                  customLabel={i18n.t('Name')}
                  placeholder={i18n.t('Name')}
                  register={'name'}
                  validation={{
                    required: i18n.t('Name is required') as string,
                  }}
                />
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol>
                <BigUp.TextareaOutlined
                  customLabel={i18n.t('Description')}
                  name='description'
                  placeholder={i18n.t('Description')}
                  register={'description'}
                />
              </IonCol>
            </IonRow>

            <IonRow className='ion-justify-content-end'>

              <IonCol size='12' className='ion-text-left'>
                <BigUp.Label.Thick label={i18n.t('Attach to project')} className='ion-no-margin' position='stacked' />
                <IonItem className='ion-no-padding' lines='none'>
                  <IonSelect
                    slot='start'
                    value={projectSelect}
                    placeholder={i18n.t('Select a project')}
                    onIonChange={e => setProjectSelect(e.detail.value)}
                  >
                    <IonSelectOption value={''}>{i18n.t('No project')}</IonSelectOption>
                    {projects?.map((project) => (
                      <IonSelectOption key={project.id} value={project.id}>
                        {project.name}
                      </IonSelectOption>
                    ))}
                  </IonSelect>
                </IonItem>
              </IonCol>
            </IonRow>
            <IonRow className="ion-justify-content-center ion-margin-vertical">
              <IonCol size='5'>
                <BigUp.Buttons.Primary
                  expand="block"
                  type='submit'
                  title={i18n.t('Duplicate')}
                />
              </IonCol>
            </IonRow>
          </IonGrid>
        </form>
      </FormProvider>
    </IonGrid>
  );
};

export default DuplicatePrecalculation;
