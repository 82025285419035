import { IonIcon } from '@ionic/react';
import React, { useMemo } from 'react';

import BigUp from '../../../../components/UI';
import type { BadgeProps } from '../../../../components/UI/Icons/Badge';
import type { PatchColors } from '../../../../components/UI/Inputs/ColorPatchPicker/colorPatches';
import getPropertiesByType from '../../DocumentsListTable/documentSwitch';

interface CategoryTitleColumnProps {
  value?: string;
  attributes?: object & { color: PatchColors };
  size?: BadgeProps['size'];
  thumbnail?: boolean;
  isBadge?: boolean | undefined;
}

export const getDocumentTypeColor = (color: PatchColors, system_record_class: string = undefined) => {
  if (system_record_class) {
    const type = getPropertiesByType(system_record_class);
    if (type?.icon) {
      return type.icon;
    }
  }
  const colorImportName = (color ?? '').charAt(0).toUpperCase() + (color ?? '').slice(1);
  return BigUp.Icons.Svg[`DocumentFolder${colorImportName}`] ?? BigUp.Icons.Svg.DocumentFolderGrey;
};

const CategoryTitleColumn: React.FC<React.PropsWithChildren<CategoryTitleColumnProps>> = ({ attributes, isBadge = true, size = 'small', thumbnail, value }) => {
  const { color } = attributes || { color: 'purple' };
  const folderIcon = useMemo(() => {
    return getDocumentTypeColor(color, attributes?.system_record_class);
  }, [color]);

  return (
    <div>
      {thumbnail && (
        <IonIcon
          src={folderIcon}
          style={{ width: '52px', height: '52px' }}
        />
      )}
      {(isBadge && !thumbnail) && (
        <BigUp.IconBadge size={size}>
          <IonIcon src={folderIcon} />
        </BigUp.IconBadge>
      )}
      {(!isBadge && !thumbnail) && (
        <IonIcon
          src={folderIcon}
          style={{
            ...size === 'x-large'
              ? { width: '32px', height: '32px' }
              : ''
          }}
        />
      )}

      {value}
    </div>
  );
};

export default CategoryTitleColumn;
