import { createSlice } from '@reduxjs/toolkit';
import type { SliceCaseReducers } from '@reduxjs/toolkit/src';
import type { E2U } from '@techlove/easy2use-typings';

export interface FileSelectionReducer {
  files: E2U.V1.Models.File[] | undefined;
  selectedFile: E2U.V1.Models.File | any | undefined;
  selectedFiles: E2U.V1.Models.File[] | undefined;
  selectedDocument: E2U.V1.Models.Document | undefined;
  forceReloadDocument: boolean;
  toggleListView: boolean;
  search: string;
}

export const fileSlice = createSlice<
FileSelectionReducer,
SliceCaseReducers<FileSelectionReducer>,
'fileSelection',
{
  setFiles:(state: FileSelectionReducer, action: { payload: E2U.V1.Models.File[] }) => void;
  setSelectedFile: (state: FileSelectionReducer, action: { payload: E2U.V1.Models.File }) => void;
  setSelectedFiles: (state: FileSelectionReducer, action: { payload: E2U.V1.Models.File[] }) => void;
  setSelectedDocument: (state: FileSelectionReducer, action: { payload: E2U.V1.Models.Document }) => void;
  setForceReloadDocument: (state: FileSelectionReducer, action: { payload: boolean }) => void;
  setToggleListView: (state: FileSelectionReducer, action: {payload:boolean}) => void;
  setSearch: (state: FileSelectionReducer, action: {payload:string}) => void;
    }
    >({
      name: 'fileSelection',
      initialState: {
        files: undefined,
        selectedFile: undefined,
        selectedFiles: undefined,
        selectedDocument: undefined,
        forceReloadDocument: false,
        toggleListView: false,
        search: ''
      },
      reducers: {
        setFiles: (state, action) => {
          state.files = action.payload;
        },
        setSelectedFile: (state, action) => {
          state.selectedFile = action.payload;
        },
        setSelectedFiles: (state, action) => {
          state.selectedFiles = action.payload;
        },
        setSelectedDocument: (state, action) => {
          state.selectedDocument = action.payload;
        },
        setForceReloadDocument: (state, action) => {
          state.forceReloadDocument = action.payload;
        },
        setToggleListView: (state, action) => {
          state.toggleListView = action.payload;
        },
        setSearch: (state, action) => {
          state.search = action.payload;
        }
      }
    });

export const {
  setFiles,
  setForceReloadDocument,
  setSearch,
  setSelectedDocument,
  setSelectedFile,
  setSelectedFiles,
  setToggleListView
} = fileSlice.actions;
export default fileSlice.reducer;
