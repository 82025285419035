import type { E2U } from '@techlove/easy2use-typings';
import { useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import { networking } from '../api/networking';
import toasters from '../components/UI/Toasts';
import { useAppSelector } from '../hooks';
import { setSelectedCategory } from '../reducers/document';
import { setFiles, setForceReloadDocument, setSearch, setSelectedDocument, setSelectedFile, setSelectedFiles, setToggleListView } from '../reducers/files';
import store from '../store';
import { formatToYYYYMMDD } from '../tools/formatDates';

interface Props {
  document?: {
    name?: string,
    description?: string
  };
  callbackOnSuccess: () => void;
}

const todaysDate = formatToYYYYMMDD(new Date().toISOString());

export const useCreateAndAttachFilesOnCategory = (props?: Props) => {
  const selectedFiles = useAppSelector((state) => state.fileSelection.selectedFiles);
  const selectedProjectId = useAppSelector((state) => state.project.selectedProject?.id);
  const selectedDocumentCategory = useAppSelector(state => state.document.selectedCategory);

  const { t } = useTranslation();
  const { documentUuid } = useParams<{ documentUuid: string | undefined }>();

  const categoryTags = useMemo(() => selectedDocumentCategory?.category_tags ?? [], [selectedDocumentCategory]);

  const clearReducers = useClearFileUploadReducers();

  const clearSelection = () => {
    clearReducers();
  };

  const toastMessages = {
    error: {
      message: t('Oops, something went wrong')
    },
    success: {
      message: t('Successfully uploaded files')
    },
    pending: {
      message: t('Creating document for files')
    }
  };

  const createDocumentAndAttachAllFilesSelected = () => {
    if (documentUuid) {
      toasters.promiseToast(networking.put(`/api/v1/documents/${documentUuid}`)
        .then(() => {
          selectedFiles?.forEach((file: any) => {
            networking.post(`/api/v1/documents/${documentUuid}/files/${file.id}`)
              .then(() => {
                if (categoryTags && categoryTags.length) {
                  categoryTags.forEach((tag: E2U.V1.Models.Tag) => {
                    networking.post(`/api/v1/documents/${documentUuid}/related_tags/${tag.id}`);
                  });
                }
                clearSelection();
                store.dispatch(setForceReloadDocument(true));
              });
          });
        }), {
        ...toastMessages
      });
    } else {
      toasters.promiseToast(
        networking.post(`/api/v1/projects/${selectedProjectId}/documents`, {
          name: props?.document?.name || `Document - ${todaysDate}`,
          description: props?.document?.description || '',
        })
          .then((response) => {
            const documentId = response.data.data.id;
            selectedFiles?.forEach((file: any) => {
              networking.post(`/api/v1/documents/${documentId}/files/${file.id}`)
                .then(() => {
                  if (categoryTags && categoryTags.length) {
                    categoryTags.forEach((tag: E2U.V1.Models.Tag) => {
                      networking.post(`/api/v1/documents/${documentId}/related_tags/${tag.id}`);
                    });
                  }
                });
            });
          }), {
          ...toastMessages
        });
    }
    props?.callbackOnSuccess && props.callbackOnSuccess();
  };

  return {
    createDocumentAndAttachAllFilesSelected,
    clearSelection,
  };
};

export const useClearFileUploadReducers = () => {
  const clearReducers = () => {
    store.dispatch(setSelectedFile(undefined));
    store.dispatch(setSelectedFiles([]));
    store.dispatch(setSelectedDocument(undefined));
    store.dispatch(setSelectedCategory(undefined));
    store.dispatch(setFiles([]));
  };
  return clearReducers;
};
