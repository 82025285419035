import { IonButton, IonIcon, IonSpinner } from '@ionic/react';
import React from 'react';

import { BigUp } from '..';
import AppSettings from './AppSettings';
import styles from './Buttons.module.scss';
import CardButton from './CardButton';
import type { ButtonProps, ButtonPropsV2, CustomCSSProperties, IconButtonProps, SecondaryProps, SelectButtonProps } from './interface';
import ListToggle from './ListToggle';
import BankIdAuthenticate from '../../ButtonComponent/BankIdAuthenticate';

const Button: React.FC<ButtonPropsV2> = ({
  children,
  className = '',
  customIcon,
  disabled = false,
  icon,
  loading = false,
  title,
  ...props
}) => {
  return (
    <IonButton
      mode="md"
      className={`${styles.button} ${className}`}
      disabled={loading || disabled}
      shape="round"
      {...props}
    >
      {icon && <IonIcon slot="start" icon={icon.icon} />}
      {customIcon && customIcon}
      {loading && <IonSpinner style={{ height: 16 }} />}
      {title}
      {children}
    </IonButton>
  );
};

const Primary: React.FC<ButtonProps> = (props) => {
  return (
    <Button {...props} className={styles['primary-button']} />
  );
};

const Secondary: React.FC<SecondaryProps> = ({ color = 'default', size = 'default', ...props }) => {
  return (
    <Button
      {...props}
      size={size}
      className={`${styles['secondary-button']} ${styles[`secondary-button-${size}--${color}`]}`}
    />
  );
};

const SecondaryIcon: React.FC<SecondaryProps> = ({ size = 'default', ...props }) => {
  return (
    <Button
      {...props}
      size={size}
      className={`${styles['secondary-button']} ${styles[`secondary-button-icon-${size}`]}`}
    />
  );
};

const Danger: React.FC<ButtonProps> = (props) => {
  return (
    <Button {...props} className={styles[`danger-button`]} />
  );
};

const Dark: React.FC<ButtonProps> = (props) => {
  return (
    <Button {...props} className={styles['dark-button']} />
  );
};

const Gray: React.FC<ButtonProps> = (props) => {
  return (
    <Button {...props} className={styles['gray-button']} />
  );
};

const Select: React.FC<SelectButtonProps> = ({ active = false, ...props }) => {
  return (
    <Button {...props} className={styles[`select-button-${active ? 'active' : 'inactive'}`]} />
  );
};

const Regular: React.FC<ButtonProps> = ({ title, ...props }) => {
  return (
    <IonButton {...props}>
      {props.icon?.slot === 'start' && <IonIcon slot='start' {...props.icon} />}
      {title === undefined ? props.children : title}
      {props.customIcon}
      {props.icon?.slot === 'end' && <IonIcon slot='start' {...props.icon} />}
    </IonButton>
  );
};

const Responsive: React.FC<ButtonProps & IconButtonProps> = ({ title, ...props }) => {
  return (
    <>
      {props.isDesktop ? <Regular title={title} {...props} /> : <Icon {...props} />}
    </>

  );
};

const Icon: React.FC<IconButtonProps> = ({ ...props }) => {
  const default_padding: IconButtonProps['padding'] = props.padding || 28;
  const default_fontSize = props.icon?.style?.fontSize || 20;

  const default_styles: CustomCSSProperties = {
    '--padding-start': default_padding,
    '--padding-end': default_padding,
    '--padding-top': default_padding,
    '--padding-bottom': default_padding,
    width: default_padding * 2 || '50px',
    height: default_padding * 2 || '50px',
  };

  return (
    <div className={styles.roundButtonWrapper}>
      {props.title && <BigUp.Label.Regular label={props.title} className='ion-no-margin' />}
      <IonButton {...props} shape={props.shape || 'round'} style={{ ...default_styles, ...props.style }} mode={props.mode || 'md'}>
        {props.icon && (
          <IonIcon slot='icon-only' {...props.icon} style={{ ...props.style, fontSize: default_fontSize }} />
        )}
        {props.customIcon && props.customIcon}
      </IonButton>
    </div>
  );
};

const Buttons = {
  Regular,
  Secondary,
  SecondaryIcon,
  Icon,
  Responsive,
  AppSettings,
  BankIdAuthenticate,
  Primary,
  Danger,
  Select,
  Dark,
  Gray,
  ListToggle,
  CardButton,
};

export default Buttons;
