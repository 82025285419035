import { IonCol, IonGrid, IonRow } from '@ionic/react';
import { cloudUpload } from 'ionicons/icons';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import BigUp from '../../..';
import type { NavigationProps } from './BigUp/types';
import ContentWrapper from './ContentWrapper';
import { useAppSelector } from '../../../../../hooks';
import { useFileReader } from '../../../../../hooks/useFileReader';
import DragAndDrop from '../../../DragAnddrop/DragAndDrop';
import EmptyList from '../../../EmptyList';
import type { FilePreviewProps } from '../../../Preview/types';

const DeviceFiles: React.FC<NavigationProps> = (props) => {
  const { error, loading, readFiles, result, thumbnails } = useFileReader();
  const { t } = useTranslation();
  const toggle = useAppSelector(state => state.fileSelection.toggleListView);
  const fileProps = props.fileProps;
  const files = fileProps?.filesToUpload;

  useEffect(() => {
    if (files && files.length > 0) {
      readFiles(files);
    }
  }, [files, readFiles]);

  return (
    <ContentWrapper>
      <DragAndDrop onFilesSelect={(files) => fileProps?.handleFileSelection(files)}>
        <EmptyList
          title={t('Click to upload files')}
          message={t('To upload files, click here')}
          icon={{
            icon: cloudUpload,
            size: 80
          }}
        />
      </DragAndDrop>
      <BigUp.Preview.PreviewListWrapper toggle={toggle}>
        {(files && files.length !== 0) && (
          files.map((file, index) => {
            const listItemProps: FilePreviewProps = {
              fileName: file.name,
              fileType: file.type,
              fileUrl: thumbnails[index] || '',
              onDelete: () => fileProps?.removeFileFromFilesToUpload(file),
              result,
            };
            return (
              <React.Fragment key={file.name}>
                {!toggle
                  ? (
                    loading
                      ? (
                        <BigUp.Preview.PreviewCard
                          fileName={t('loading...')}
                          fileType={t('loading...')}
                          fileUrl=''
                          onDelete={() => { }}
                        />
                      )
                      : <BigUp.Preview.PreviewCard {...listItemProps} />
                  )
                  : <BigUp.Preview.PreviewItem {...listItemProps} />
                }
              </React.Fragment>
            );
          })
        )}
        {error && <p>{error.message}</p>}
      </BigUp.Preview.PreviewListWrapper>
    </ContentWrapper>
  );
};

export default DeviceFiles;
