import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';
import { add, imageOutline, scan } from 'ionicons/icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router';

import styles from './DocumentActionsButton.module.scss';
import { helpers } from './helpers';
import { networking } from '../../api/networking';
import { useAppSelector } from '../../hooks';
import useCameraUpload from '../../hooks/useCameraUpload';
import useFileUpload from '../../hooks/useFileUpload';
import useModal from '../../hooks/useModal';
import { setForceReloadDocument } from '../../reducers/files';
import store from '../../store';
import { importData } from '../../tools/importData';
import DocumentFormModal from '../DocumentFormModal';
import BigUp from '../UI';
import FabButton from '../UI/Buttons/Fabs/FabButton';
import type { ListItems } from '../UI/Modal/ListModal/types';
import toasters from '../UI/Toasts';

const DocumentActionsButton: React.FC = () => {
  const { t } = useTranslation();
  const modal = useModal();
  const [modalOpen, setModalOpen] = useState(false);
  const [optionsVisible, setOptionsVisible] = useState(false);
  const [documentFormOpen, setDocumentFormOpen] = useState(false);

  const isDesktop = useAppSelector(state => state.desktopView.isDesktop);
  const selectedProject = useAppSelector(state => state.project.selectedProject);
  const selectedDocumentCategory = useAppSelector(state => state.document.selectedCategory);

  const { documentUuid } = useParams<{ documentUuid: string | undefined }>();
  const {
    getUploadedFiles,
    handleFileSelection,
    onlyUploadFiles,
    resetFilesToUpload
  } = useFileUpload();

  const files = getUploadedFiles() ?? [];
  const cameraProps = useCameraUpload();

  const categoryTags = useMemo(() => selectedDocumentCategory?.category_tags ?? [], [selectedDocumentCategory]);

  const takePhoto = () => {
    Camera.getPhoto({
      resultType: CameraResultType.DataUrl,
      source: CameraSource.Camera,
      quality: 100,
    })
      .then(async (photo) => {
        const fileName = `${new Date().getTime()}.${photo.format}`;
        const blob: any = cameraProps.dataURLToBlob(photo.dataUrl);
        if (!blob) return;
        const file = new File([blob], fileName, { type: 'image/*' });

        cameraProps.handleTakenPhoto([photo]);
        uploadTakenPhoto([file]).then(() => {
          toasters.createToast({
            message: t('Your photo has been uploaded')
          }, 'success');
          store.dispatch(setForceReloadDocument(true));
        });
      })
      .catch((error) => Sentry.captureException(error));
  };

  const uploadTakenPhoto = async (photos: File[]) => {
    if (!photos || photos.length === 0) return;

    const uploadPromises = photos.map((photo) => cameraProps.uploadPhoto(photo));

    const responses = await Promise.all(uploadPromises)
      .catch((error) => {
        Sentry.captureException(error);
        return [];
      });

    const fileIds: any = responses
      .map((response) => response?.data?.data?.id)
      .filter((id) => id);

    if (fileIds.length === 0) return;

    if (documentUuid) {
      await helpers.attachFilesToDocument(fileIds, documentUuid);
    } else if (selectedDocumentCategory) {
      const fileName = photos[0]?.name;
      const documentId = await helpers.createDocument(fileName, selectedProject?.id)
        .catch((error) => {
          Sentry.captureException(error);
          return null;
        });
      if (documentId) {
        await helpers.attachFilesToDocument(fileIds, documentId);
        helpers.attachCategoryTags(documentId, categoryTags);
      }
    } else {
      const fileName = photos[0]?.name;
      const documentId = await helpers.createDocument(fileName, selectedProject?.id)
        .catch((error) => {
          Sentry.captureException(error);
          return null;
        });
      if (documentId) {
        await helpers.attachFilesToDocument(fileIds, documentId);
      }
    }
  };

  const createDocumentFromFiles = () => {
    const files = onlyUploadFiles();
    const uploadPromises = files.map((filePromise) => {
      return filePromise
        .then((fileResponse) => {
          const fileId = fileResponse.data.data.id;
          const fileName = fileResponse.data.data.name;

          return (documentUuid
            ? Promise.resolve(documentUuid)
            : helpers.createDocument(fileName, selectedProject?.id)
          )
            .catch((error) => {
              Sentry.captureException(error);
              return null;
            })
            .then((documentId) => {
              networking.post(`/api/v1/documents/${documentId}/files/${fileId}`)
                .then(() => {
                  if (categoryTags?.length) {
                    const tagPromises = categoryTags.map((tag: E2U.V1.Models.Tag) =>
                      networking.post(`/api/v1/documents/${documentId}/related_tags/${tag.id}`)
                    );
                    Promise.all(tagPromises);
                  }
                  store.dispatch(setForceReloadDocument(true));
                });
            });
        });
    });
    toasters.promiseToast(Promise.all(uploadPromises)
      .then(() => resetFilesToUpload())
      .catch((error) => Sentry.captureException(error)), {
      pending: { message: t('Saving files...') },
      error: { message: t('Something went wrong') },
      success: { message: t('Files has been saved') }
    });
  };

  const handlePopoverPress = (callback: () => void) => {
    setModalOpen(false);
    callback();
  };

  const uploadMedia = () => {
    importData(handleFileSelection, 'image/*, video/*');
  };

  const fileUploadOptions: ListItems[] = [
    {
      value: 'file-upload-modal',
      label: t('Upload from device'),
      icon: {
        icon: BigUp.Icons.Svg.FolderOutlined
      },
      onClick: () => handlePopoverPress(modal.openModal),
    },
    {
      value: 'file-upload-photos-and-files',
      label: t('Upload photos or video'),
      icon: {
        icon: imageOutline,
        color: 'dark',
      },
      onClick: () => handlePopoverPress(() => uploadMedia())
    },
    {
      value: 'use-camera',
      label: t('Take a photo'),
      icon: {
        icon: BigUp.Icons.Svg.CameraOutlined
      },
      onClick: () => handlePopoverPress(() => takePhoto())
    },
    {
      value: 'scan-document',
      label: t('Scan document (coming soon)'),
      disabled: true,
      icon: {
        icon: scan
      },
      onClick: () => toasters.createToast({
        message: t('This feature is coming soon!')
      }, 'warning'),
    },
  ];

  useEffect(() => {
    if (files.length > 0) {
      createDocumentFromFiles();
    }
  }, [files]);

  return (
    <>
      <FabButton
        icon={{
          icon: add,
        }}
        onClick={() => setOptionsVisible(!optionsVisible)}
        vertical={'bottom'}
        horizontal={'end'}
        className={`
          ${styles['documents-action-button']} 
          ${isDesktop && styles['DocumentActionsButton--desktop']}
        `}
        list={{
          buttons: [
            {
              icon: {
                icon: BigUp.Icons.Svg.Document,
              },
              label: t('Create document'),
              onClick: () => setDocumentFormOpen(true)
            },
            {
              icon: {
                icon: BigUp.Icons.Svg.Attachments,
              },
              onClick: () => setModalOpen(true),
              label: t('Upload files'),
            }
          ],
          side: 'top'
        }}
      />
      <BigUp.Modal.ListModal
        isOpen={modalOpen}
        dismiss={() => setModalOpen(false)}
        items={fileUploadOptions}
        title={t('Upload files')}
        subTitle={t(`Generate document from files`)}
      />
      <DocumentFormModal
        open={documentFormOpen}
        onClose={() => setDocumentFormOpen(false)}
        onSaved={() => setDocumentFormOpen(false)}
      />
      <BigUp.Modal.FileUpload
        modal={{
          isOpen: modal.isModalOpen,
          dismiss: modal.closeModal
        }}
      />
    </>
  );
};

export default DocumentActionsButton;
