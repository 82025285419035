import * as Sentry from '@sentry/capacitor';
import type { E2U } from '@techlove/easy2use-typings';

import { networking } from '../../api/networking';

const createDocument = async (fileName: string, projectId: E2U.V1.Models.Project['id']) => {
  return networking
    .post(`/api/v1/documents`, {
      name: fileName,
      description: '',
      project_id: projectId,
    })
    .then((response) => {
      const { id } = response.data.data;
      return id;
    });
};

const attachFilesToDocument = async (fileIds: string[], documentId: E2U.V1.Models.Document['id']) => {
  const attachPromises = fileIds.map((fileId) =>
    networking.post(`/api/v1/documents/${documentId}/files/${fileId}`)
  );
  await Promise.all(attachPromises)
    .catch((error) => {
      Sentry.captureException(error);
    });
};

const attachCategoryTags = async (documentId: E2U.V1.Models.Document['id'], tags: E2U.V1.Models.Tag[]) => {
  if (tags && tags.length) {
    const tagPromises = tags.map((tag: E2U.V1.Models.Tag) =>
      networking.post(`/api/v1/documents/${documentId}/related_tags/${tag.id}`)
    );
    await Promise.all(tagPromises)
      .catch((error) => {
        Sentry.captureException(error);
      });
  }
};

export const helpers = {
  createDocument,
  attachFilesToDocument,
  attachCategoryTags,
};
