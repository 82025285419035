import { IonBreadcrumb } from '@ionic/react';

import styles from './Breadcrumb.module.scss';

interface BreadcrumbProps {
  label: React.ReactNode;
  onClick: () => void | undefined;
  active?: boolean;
}

const Breadcrumb: React.FC<BreadcrumbProps> = ({ active = false, label, onClick }) => {
  return (
    <IonBreadcrumb
      color={'medium'}
      onClick={onClick}
      className={styles['breadcrumb-container']}
    >
      <p style={{
        color: active ? 'var(--ion-color-secondary)' : 'var(--ion-color-medium)',
      }}>
        {label}
      </p>
    </IonBreadcrumb>
  );
};

export default Breadcrumb;
